import React from "react"

function TextBannerBlog() {
  return (
    <div className="text-left  pl-5 tablet:pl-20 bigdesktop:pl-24 text-white mb-32 tablet:mb-0">
      <p className="text-56 desktop:text-64 bigdesktop:text-80 font-byom-bold">
        <span className="textOurExperience2 flex">
          <span className="subtitleTextTalentX">X&nbsp;</span>
          <span className="subtitleTextTalent">Media</span>
        </span>
        <span className="textBanners">
          <span className="colorTextExperience2">Blog</span>
          <span className="colorTextExperience2">&nbsp;</span>
        </span>
      </p>
    </div>
  )
}

export default TextBannerBlog
