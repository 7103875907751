import React, { useState, useEffect, useContext, useRef } from "react"
import i18n from "i18next"
import { LoadingContext } from "../context/LoadingContext"
import { Link } from "gatsby"
import { getBlogsRef, getBlogsPosts } from "../../utils/api"
import limpiarTexto from "../xMediaBlogArticle/utilsBlogs"

const DynamicBlogs = ({ typeFilter }) => {
  const [blogs, setBlogs] = useState([
    {
      category: "",
      image: "",
      title: "",
      id: "",
      data: "",
    },
  ])
  const initialValue = typeFilter && typeFilter.typeF ? typeFilter.typeF : 1
  const [menu, setMenu] = useState(initialValue)
  const [articlesArray, setArticlesArray] = useState([])
  const { windowWidth } = useContext(LoadingContext)
  const containerRef = useRef(null)
  const [startX, setStartX] = useState(null)
  const [scrollLeft, setScrollLeft] = useState(0)

  const getRef = async () => {
    try {
      const response = await getBlogsRef()
      const jsonData = response.data
      const refValue = jsonData.refs[0].ref
      return refValue
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.message)
      throw error
    }
  }

  const getBlogs = async () => {
    try {
      const lang = i18n.language === "en" ? "&lang=en-us" : ""
      const ref = await getRef()
      const response = await getBlogsPosts(ref + lang)
      const jsonData = response.data
      const results = jsonData.results

      const mappedBlogs = results.map(result => ({
        category: result.data.blog_category,
        image: result.data.thumbnail_image.url,
        title: result.data.blog_title[0].text,
        id: result.id,
        data: result.data.text_and_images,
        date: new Date(result.last_publication_date),
      }))
      const blogsOrdenados = mappedBlogs.sort((a, b) => b.date - a.date)
      setBlogs(blogsOrdenados)
    } catch (error) {
      console.error("Error al realizar la solicitud:", error.message)
    }
  }

  useEffect(() => {
    if (menu == 1) {
      setArticlesArray(blogs)
    } else if (menu == 2) {
      setArticlesArray(blogs.filter(option => option.category == "Business"))
    } else if (menu == 3) {
      setArticlesArray(blogs.filter(option => option.category == "Data Base"))
    } else if (menu == 4) {
      setArticlesArray(blogs.filter(option => option.category == "Engineering"))
    } else if (menu == 5) {
      setArticlesArray(blogs.filter(option => option.category == "IT Security"))
    } else {
      setArticlesArray(
        blogs.filter(option => option.category == "Software Development")
      )
    }
  }, [menu, blogs])

  useEffect(() => {
    getBlogs()
  }, [i18n.language])

  const handleTouchStart = e => {
    setStartX(e.touches[0].clientX)
    setScrollLeft(containerRef.current.scrollLeft)
  }

  const handleTouchMove = e => {
    if (!startX) return
    const x = e.touches[0].clientX
    const diff = startX - x
    containerRef.current.scrollLeft = scrollLeft + diff
  }

  const handleTouchEnd = () => {
    setStartX(null)
  }

  const menuItems = [
    { id: 1, label: i18n.t("pageMediaBlogArticle.sidebar.categories.all")},
    { id: 2, label: i18n.t("pageMediaBlogArticle.sidebar.categories.category1")},
    { id: 3, label: i18n.t("pageMediaBlogArticle.sidebar.categories.category2")},
    { id: 4, label: i18n.t("pageMediaBlogArticle.sidebar.categories.category4")},
    { id: 5, label: i18n.t("pageMediaBlogArticle.sidebar.categories.category3")},
    { id: 6, label: i18n.t("pageMediaBlogArticle.sidebar.categories.category5")},
  ]

  return (
    <>
      {windowWidth <= 1025 ? (
        <>
          <div className="mt-24 ml-8">
            <div
              className="carousel-container"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
              ref={containerRef}
            >
              <div className="carousel-buttons">
                {menuItems.map(menuItem => (
                  <button
                    key={menuItem.id}
                    className={`contentMenuArticles${
                      menu === menuItem.id ? "" : "NoSelect"
                    }MediaBlog`}
                    onClick={() => setMenu(menuItem.id)}
                  >
                    <p
                      className={`textMenuArticles${
                        menu === menuItem.id ? "" : "NoSelect"
                      }`}
                    >
                      {menuItem.label}
                    </p>
                  </button>
                ))}
              </div>
            </div>
          </div>
          {articlesArray.length === 0 && (
            <div className="noFoundAricles">
              <p className="textNoFoundAricles">
                {" "}
                {i18n.language == "en"
                  ? "No Blogs Found"
                  : "No se encontraron blogs"}
              </p>
            </div>
          )}
          {/* Mobile */}
          <div className="mt-16 mb-24">
            {articlesArray.map(card => (
              <Link
                key={card.id}
                to={`/blogs/${
                  i18n.language === "es" ? "es" : "en"
                }/${limpiarTexto(card.title)}/`}
              >
                <div className="mobile:w-full">
                  <div className="cardMediaBlogMobile my-6" key={card.id}>
                    <div
                      style={{
                        backgroundImage: "url(" + card.image + ")",
                      }}
                    >
                      <div
                        className="contentTextCardArticlesMobile flex flex-col justify-end pb-8"
                        style={{
                          background:
                            "linear-gradient(0deg, #000538 10%, rgba(0, 0, 0, 0) 65%)",
                        }}
                      >
                        <div className="text-left w-auto ">
                          <p className="pr-2 pl-2 titleCardArticle desktop:text-20">
                            {card.title}
                          </p>
                          <p className="pr-2 pl-2 textCardArticle desktop:text-20">
                            {card.category}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        </>
      ) : (
        <>
          <div className="mobile:w-full mobile:pl-4 mobile:pr-4">
            <div className="flip-card-inner2 mt-24">
              <div className="desktop:flex">
                <div className="desktop:flex MenuArticles desktop:mr-16 bigdesktop:mr-20 mobile:flex mobile:flex-wrap mobile:mt-8">
                  {menuItems.map(item => (
                    <button
                      key={item.id}
                      className={
                        menu == item.id.toString()
                          ? "contentMenuArticlesMediaBlog"
                          : "contentMenuArticlesNoSelectMediaBlog"
                      }
                      onClick={() => setMenu(item.id)}
                      style={{ alignSelf: "center" }}
                    >
                      <p
                        className={
                          menu == item.id.toString()
                            ? "textMenuArticles"
                            : "textMenuArticlesNoSelect"
                        }
                      >
                        {item.label}
                      </p>
                    </button>
                  ))}
                </div>
              </div>

              {articlesArray.length === 0 && (
                <div className="noFoundAricles">
                  <p className="textNoFoundAricles">
                    {" "}
                    {i18n.language == "en"
                      ? "No Blogs Found"
                      : "No se encontraron blogs"}
                  </p>
                </div>
              )}

              {/* Desktop */}
              <div
                className={
                  windowWidth >= 1025 && windowWidth < 1920
                    ? "containerMediaBlog"
                    : "containerMediaBlogBigDesktop"
                }
              >
                <div
                  className={
                    windowWidth >= 1025 && windowWidth < 1920
                      ? "gridMediaBlog"
                      : "gridMediaBlogBigDesktop"
                  }
                >
                  {articlesArray.map(card => (
                    <Link
                      key={card.id}
                      to={`/blogs/${
                        i18n.language === "es" ? "es" : "en"
                      }/${limpiarTexto(card.title)}/`}
                    >
                      <div
                        className={
                          windowWidth >= 1920
                            ? "cardMediaBlogBigDesktop"
                            : "cardMediaBlog"
                        }
                        key={card.id}
                      >
                        <div
                          className={
                            windowWidth >= 1920
                              ? "cardMediaBlogImage"
                              : undefined
                          }
                          style={{
                            backgroundImage: "url(" + card.image + ")",
                          }}
                        >
                          <div
                            className={
                              windowWidth >= 1920
                                ? "contentTextCardArticlesBigDesktop flex flex-col justify-end pb-12"
                                : "contentTextCardArticles flex flex-col justify-end pb-12"
                            }
                            style={{
                              background:
                                "linear-gradient(0deg, #000538 15%, rgba(0, 0, 0, 0) 65%)",
                            }}
                          >
                            <div className="text-left w-auto ">
                              <p className="pr-2 pl-2 titleCardArticle desktop:text-20">
                                {card.title}
                              </p>
                              <p className="pr-2 pl-2 textCardArticle desktop:text-20">
                                {card.category}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default DynamicBlogs
