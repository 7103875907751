import React from "react"
import imageBanner from "../images/blog/BannerBlog.png"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerXMmediaPodcast from "../components/xMmediaPodcast/BannerXMmediaPodcast"
import TextBannerBlog from "../components/mediaBlog/TextBannerBlog"
import { withNamespaces } from "react-i18next"
import DynamicBlogs from "../components/mediaBlog/DynamicBlogs"

const mediaBlog = ({ location }) => {
  const typeFilter = location.state
  return (
    <Layout>
      <SEO
        title={"Media Blog | Exsis Digital Angels"}
        description={"Media Blog | Specialists in Tech Talent"}
      />
      <BannerXMmediaPodcast
        image={imageBanner}
        TextComponent={TextBannerBlog}
      />
      <DynamicBlogs typeFilter={typeFilter} />
    </Layout>
  )
}

export default withNamespaces()(mediaBlog)
